import {css, html, LitElement} from "lit";
import {gettext} from "../lit-directives.js";
import {preference_units} from "../preference-units.js";

const
    text_height_label = gettext('Height'),
    text_height_label_note = gettext('waterline to top of wheelhouse'),
    text_boat_speed_label = gettext('Economic boat speed'),
    text_rpm = gettext('Economic RPM'),
    text_fuel_consumption = gettext('Economic fuel consumption');

let speedPreference = preference_units.wind_speed,
    heightPreference = preference_units.wave_height,
    fuelConsumptionPreference = preference_units.fuel_volume;


export class PWRoutingPowerSettings extends LitElement {
    static get properties() {
        return {
            significantHeight: {type: String},
            economicBoatSpeed: {type: String},
            economicRPM: {type: String},
            economicFuelConsumption: {type: String}
        }
    }

    constructor() {
        super();
        this.significantHeight = '';
        this.economicBoatSpeed = '';
        this.economicRPM = '';
        this.economicFuelConsumption = '';
        this.fieldList = ["significantHeight", "economicBoatSpeed", "economicRPM", "economicFuelConsumption"];
    }

    onChangeField(e) {
        const fieldElem = $j(e.target);
        const value = fieldElem.val().trim();
        let fieldName = fieldElem.attr('name');
        this[fieldName] = value;
        let invalid = this.isBadValue(value);
        if (!invalid) {
            this.commitField(fieldName);
        }
    }

    isBadValue(value) {
        try {
            const floatValue = Number(value);
            return isNaN(floatValue) || floatValue <= 0;
        } catch {
            return true;
        }
    }

    commitField(fieldName) {
        this.dispatchEvent(new CustomEvent('commit-field', {
            detail: {
                fieldName: fieldName,
                fieldValue: this[fieldName]
            }
        }));
    }

    connectedCallback() {
        super.connectedCallback();
        console.log(`preference_units: ${preference_units}`);
    }

    render() {
        return html`
          <div class="power-settings">
            <span>${text_height_label} (${text_height_label_note}, ${heightPreference.symbol}):</span>
            <input size="5" name="significantHeight" type="text" value="${this.significantHeight}" min="1" max="10" @change=${this.onChangeField}>
            <span>${text_boat_speed_label} (${speedPreference.symbol}):</span>
            <input size="5" name="economicBoatSpeed" type="text" value="${this.economicBoatSpeed}" min="1" max="15" @change=${this.onChangeField}>
            <span>${text_rpm}:</span>
            <input size="5" name="economicRPM" type="text" value="${this.economicRPM}" min="500" max="5000" @change=${this.onChangeField}>
            <span>${text_fuel_consumption} (${fuelConsumptionPreference.symbol}/h):</span>
            <input size="5" name="economicFuelConsumption" type="text" value="${this.economicFuelConsumption}" min="0" max="100" @change=${this.onChangeField}>
          </div>
        `;
    }

    static get styles() {
        return css`
            .power-settings {
                display: grid;
                grid-template-columns:auto 1fr;
                grid-gap: 20px 30px;
                justify-items: start;
                align-items: center;
                font-size: 14px;
            }

            input {
                margin: 0;
                box-sizing: border-box;
                height: 27px;
                line-height: 25px;
                padding: 0 10px;
                border: 1px solid #d4d4d4;
                -moz-border-radius: 3px;
                -webkit-border-radius: 3px;
                border-radius: 3px;
                background-color: #fff;
                -moz-box-shadow: 0 1px 0 #fff, inset 0 1px 4px rgba(0, 0, 0, .11);
                -webkit-box-shadow: 0 1px 0 #fff, inset 0 1px 4px rgba(0, 0, 0, .11);
                box-shadow: 0 1px 0 #fff, inset 0 1px 4px rgba(0, 0, 0, .11);
                color: #555;
                font-size: 13px;
                font-weight: 400;
            }
        `;
    }
}