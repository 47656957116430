import {units, formats, isMagneticAvailable} from './convertable-units.js';
import { add_conversion, remove_conversions, define_unit, define_units, identity, fully_alias_unit } from './convertable.js';
import { format1dp } from './routing-utils.js';

// unit ids are defined in constants.py, e.g. SPEED_KNOTS = 0, SPEED_BEAUFORT = 1, etc.
const unit_settings_map = {
    Units_WindSpeed:[units.knots, units.beaufort, units.metres_per_second, units.kilometres_per_hour, units.miles_per_hour],
    Units_WindDirection:[units.degrees_true, units.degrees_magnetic, units.degrees_angle],
    Units_Temperature:[units.celsius, units.fahrenheit],
    Units_Rainfall:[units.millimetres_per_hour, units.inches_per_hour],
    Units_WaveHeight:[units.metres, units.feet],
    Units_WaveDirection:[units.degrees_true, units.degrees_magnetic],
    Units_CloudCover:[units.percent],
    Units_Pressure:[units.hectopascals],
    Units_WavePeriod:[units.seconds],
    Units_CAPE:[units.joules_per_kilogram],
    Units_Distance:[units.nautical_miles, units.kilometres, units.miles],
    Units_UVIndex:[units.uv_index],
    Units_Fog: [units.fog_rime_factor],
    Units_Lightning: [units.lightning_strikes_per_100_square_kilometres],
    Units_Humidity:[units.percent],
    Units_FuelVolume:[units.litres, units.gallons_us, units.gallons_uk]
};

const unit_body_attributes = {
    Units_WindSpeed:'units-wind-speed',
    Units_WindDirection:'units-wind-direction',
    Units_Temperature:'units-temperature',
    Units_Rainfall:'units-rainfall',
    Units_WaveHeight:'units-wave-height',
    Units_WaveDirection:'units-wave-direction',
    Units_FuelVolume: 'units-fuel-volume'
};

export function getPreferenceUnitId(settingName) {
    if (window.app) {
        return app.getSetting(settingName, 0);
    }
    if (unit_body_attributes[settingName]) {
        return +$j(document.body).data(unit_body_attributes[settingName]);
    }
    return 0;
}

export function getPreferenceUnit(settingName) {
    const unitId = getPreferenceUnitId(settingName);
    const unitList = unit_settings_map[settingName];
    if (!unitList || unitId < 0 || unitId >= unitList.length) {
        return null;
    }
    let unit = unitList[unitId];
    // if (unit === units.degrees_magnetic && !units.isMagneticAvailable()) {
    //     unit = units.degrees_true;
    // }
    return unit;
}


const boat_speed_knots = define_unit({ ...units.knots, overridden:true });
add_conversion(units.knots, boat_speed_knots, identity);

const settings_units = {
    wind_speed:{ format:formats.string_1dp },
    wind_direction:{ format:formats.string_1dp },
    temperature:{ format:formats.string_1dp },
    rainfall:{ format:formats.string_1dp },
    wave_height:{ format:formats.string_1dp },
    water_speed:{ format:formats.string_1dp },
    water_direction:{ format:formats.string_1dp },
    fuel_volume:{ format:formats.string_1dp },
};
define_units('settings_units', settings_units);

export function applyPreferenceUnitSettings() {
    for (const unitName in settings_units) {
        remove_conversions(settings_units[unitName]);
    }
    const wind_speed = getPreferenceUnit('Units_WindSpeed') ?? units.knots;
    const water_speed = wind_speed === units.beaufort ? boat_speed_knots : wind_speed;
    fully_alias_unit(settings_units.wind_speed, wind_speed);
    fully_alias_unit(settings_units.wind_direction, getPreferenceUnit('Units_WindDirection') ?? units.degrees_true);
    fully_alias_unit(settings_units.temperature, getPreferenceUnit('Units_Temperature') ?? units.celsius);
    fully_alias_unit(settings_units.rainfall, getPreferenceUnit('Units_Rainfall') ?? units.millimetres_per_hour);
    fully_alias_unit(settings_units.wave_height, getPreferenceUnit('Units_WaveHeight') ?? units.metres);
    fully_alias_unit(settings_units.water_speed, water_speed);
    fully_alias_unit(settings_units.water_direction, getPreferenceUnit('Units_WaveDirection') ?? units.degrees_true);
    fully_alias_unit(settings_units.fuel_volume, getPreferenceUnit('Units_FuelVolume') ?? units.litres);
}
// set preferences from defaults, or body attributes
applyPreferenceUnitSettings();

// TODO: lat/lon formatting preferences

export const preference_units = {
    wind_speed:settings_units.wind_speed,
    wind_direction:settings_units.wind_direction,
    temperature:settings_units.temperature,
    rainfall:settings_units.rainfall,
    wave_height:settings_units.wave_height,
    wave_direction:settings_units.water_direction,
    wave_period:units.seconds,
    current_speed:settings_units.water_speed,
    current_direction:settings_units.water_direction,
    boat_speed:settings_units.water_speed,
    boat_direction:settings_units.water_direction,
    distance:units.nautical_miles,
    roll:units.degrees_angle,
    vertical_acceleration:units.g_force,
    slamming_incidence:units.slams_per_hour,
    pressure:units.hectopascals,
    cape:units.joules_per_kilogram,
    fog:units.fog_rime_factor,
    lightning:units.lightning_strikes_per_100_square_kilometres,
    fuel_volume:settings_units.fuel_volume,
};
